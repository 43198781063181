<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      small: false,
      big: false
    };
  },
  created() {
    // // 监听屏幕宽度大小
    const that = this;
    var num = 0;
    window.onresize = () => {
      num++;
      window.screenWidth = document.body.clientWidth;
      that.screenWidth = window.screenWidth;
      // alert(that.screenWidth);
      if (that.screenWidth <= 768) {
        that.small = true;
        that.big = false;
      } else {
        that.small = false;
        that.big = true;
      }
      if (num > 0) {
        this.$store.commit("setIsBig", that.big);
      }
    };
    if (num === 0) {
      var val = this.IsPC();
      this.$store.commit("setIsBig", val);
    }
  },
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "webOS",
        "BlackBerry",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
        "iOS"
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    }
  }
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
