<template>
  <div>
    <myHeader
      :headerOptions="headerOptions"
      :activeUrl.sync="activeUrl"
      :key="headerIndex"
    ></myHeader>

    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </div>
</template>
<script>
import myHeader from "../components/header";
import { GetUserDevice } from "@/api/homeGuardianship";
import { mapMutations } from "vuex";

export default {
  components: {
    myHeader,
  },
  data() {
    return {
      headerOptions: {},
      activeUrl: "",
      headerIndex: 0,
      isFirst: true,
    };
  },
  created() {
    this.getHeader();
    this.getId();
  },

  mounted: function () {},
  destroyed: function () {},
  computed: {
    key() {
      return this.$route.path;
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.getHeader();
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["setMattressInfo", "setCameraInfo"]),
    //获取id
    getId() {
      let id = this.$route.query.id || "";
      let token = this.$route.query.token || "";
      localStorage.setItem(
        "id",
        id ? id : "8a9141c6-c11e-4d4c-ab19-8f09ed2d4162"
      );
      localStorage.setItem("token", token ? token : "");
    },

    //动态配置header数据
    getHeader() {
      this.headerIndex += 1;
      let path = this.$route.path;
      this.activeUrl = path;
      console.log(this.headerIndex);
      //居家监护
      if (path.indexOf("homeGuardianship") !== -1) {
        this.headerOptions = {
          title: "智能居家监护",
          navList: [
            {
              icon: require("@/assets/img/homeGuardianship/progress.png"),
              name: "实施进度",
              url: "/homeGuardianship/progress",
              iconActive: require("@/assets/img/homeGuardianship/progressActive.png"),
            },
            {
              icon: require("@/assets/img/homeGuardianship/homeGuardianship.png"),
              name: "居家监护",
              url: "/homeGuardianship/guardianship",
              iconActive: require("@/assets/img/homeGuardianship/homeGuardianshipActive.png"),
            },
            {
              icon: require("@/assets/img/homeGuardianship/serviceManagement.png"),
              name: "服务管理",
              url: "/homeGuardianship/serviceManagement",
              iconActive: require("@/assets/img/homeGuardianship/serviceManagementActive.png"),
            },
            {
              icon: require("@/assets/img/homeGuardianship/equipment.png"),
              name: "设备管理",
              url: "/homeGuardianship/equipment",
              iconActive: require("@/assets/img/homeGuardianship/equipmentActive.png"),
            },
            {
              icon: require("@/assets/img/homeGuardianship/data.png"),
              name: "数据统计分析",
              url: "/homeGuardianship/homeData",
              iconActive: require("@/assets/img/homeGuardianship/dataActive.png"),
            },
          ],
        };
      }
      //社区安防
      else if (path.indexOf("community") !== -1) {
        this.headerOptions = {
          title: "智能社区安防",
          navList: [
            {
              icon: require("@/assets/img/homeGuardianship/equipment.png"),
              name: "设备管理",
              url: "/community/device",
              iconActive: require("@/assets/img/homeGuardianship/equipmentActive.png"),
            },
            {
              icon: require("@/assets/img/homeGuardianship/saecurity.png"),
              name: "安防服务",
              url: "/community/security",
              iconActive: require("@/assets/img/homeGuardianship/saecurityActive.png"),
            },
            // {
            //   icon: require("@/assets/img/homeGuardianship/data.png"),
            //   name: "数据统计分析",
            //   url: "/homeGuardianship/homeData",
            //   iconActive: require("@/assets/img/homeGuardianship/dataActive.png"),
            // },
          ],
        };
      }
      //服务管理
      else if (path.indexOf("serviceManagement") !== -1) {
        this.headerOptions = {
          title: "智能服务管理",
          navList: [
            {
              icon: require("@/assets/img/homeGuardianship/equipment.png"),
              name: "健康设备",
              url: "/serviceManagement/health",
              iconActive: require("@/assets/img/homeGuardianship/equipmentActive.png"),
            },
            {
              icon: require("@/assets/img/homeGuardianship/serviceManagement.png"),
              name: "健康服务",
              url: "/serviceManagement/services",
              iconActive: require("@/assets/img/homeGuardianship/serviceManagementActive.png"),
            },
            {
              icon: require("@/assets/img/homeGuardianship/serviceOrders.png"),
              name: "服务订单",
              url: "/serviceManagement/order",
              iconActive: require("@/assets/img/homeGuardianship/serviceOrdersActive.png"),
            },
            // {
            //   icon: require("@/assets/img/homeGuardianship/data.png"),
            //   name: "数据统计分析",
            //   url: "/homeGuardianship/homeData",
            //   iconActive: require("@/assets/img/homeGuardianship/dataActive.png"),
            // },
          ],
        };
      }
      //辅具租赁
      else if (path.indexOf("lease") !== -1) {
        let isHasZn = false;
        let EquipmentID = [
          "41fe33cb-c414-4f64-b02d-7947607ff406",
          "a7c885e5-8a98-4e72-956b-2390f8c1452e",
          "e615dab9-ede2-48ef-a4ff-e06433ef16a2",
        ];
        let cameraId = ["47086eb5-def9-4dd7-863d-4a95adb69486"];
        if (this.isFirst) {
          GetUserDevice({ bizType: "3" })
            .then((res) => {
              this.isFirst = false;
              let data = res.result;
              if (data && data.length > 0) {
                data.forEach((element) => {
                  //判断有没有智能智能护理床
                  if (EquipmentID.includes(element.equipmentID)) {
                    if (element.isSetup == 1) {
                      isHasZn = true;
                      this.setMattressInfo(element);
                    }
                  }
                  //判断摄像头
                  if (cameraId.includes(element.equipmentID)) {
                    if (element.isSetup == 1) {
                      this.setCameraInfo(element);
                    }
                  }
                });
              }
            })
            .then(() => {
              this.headerOptions = {
                title: "辅具租赁",
                navList: [
                  {
                    icon: require("@/assets/img/homeGuardianship/serviceManagement.png"),
                    name: "用户档案",
                    url: "/lease/profile",
                    iconActive: require("@/assets/img/homeGuardianship/serviceManagementActive.png"),
                  },
                  {
                    icon: require("@/assets/img/homeGuardianship/vitalsigns.png"),
                    name: "生命体征",
                    url: "/lease/vitalsigns",
                    iconActive: require("@/assets/img/homeGuardianship/vitalsigns1.png"),
                  },
                  {
                    icon: require("@/assets/img/homeGuardianship/healthreport.png"),
                    name: "健康报告",
                    url: "/lease/healthreport",
                    iconActive: require("@/assets/img/homeGuardianship/healthreport1.png"),
                  },
                  {
                    icon: require("@/assets/img/homeGuardianship/progress.png"),
                    name: "设备清单",
                    url: "/lease/equipmentList",
                    iconActive: require("@/assets/img/homeGuardianship/progressActive.png"),
                  },
                ],
              };
              if (!isHasZn) {
                this.headerOptions.navList.splice(1, 1);
                this.headerOptions.navList.splice(2, 2);
              }
            });
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
</style>
