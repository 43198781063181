import axios from 'axios' // 引入axios
import { Message } from 'element-ui'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + '/bizdata',
  timeout: 99999
})


// http request 拦截器
service.interceptors.request.use(
  config => {
    config.headers["userToken"] = localStorage.getItem('token');
    return config
  },
  error => {
    Message({
      showClose: true,
      message: error,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

// http response 拦截器
service.interceptors.response.use(
  response => {

    if (response.status === 200) {
      return response.data
    } else {
      Message({
        showClose: true,
        message: response.message,
        type: 'error'
      })
      return Promise.reject(response.message)
    }
  },
  error => {
    Message({
      showClose: true,
      message: error,
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default service
