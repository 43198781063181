import service from '../utils/request'

// 查看表基本信息
export const GetBizDoc = data => {
  return service({
    url: '/api/BizDoc/GetBizDoc',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data

    },
  })
}
//获取业务档案附件
export const GetBizDocAnnex = data => {
  return service({
    url: '/api/BizDoc/GetBizDocAnnex',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data

    },
  })
}

//获取报警
export const GetSOSWorkOrder = data => {
  return service({
    url: '/api/WorkOrder/GetSOSWorkOrder',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data

    },
  })
}

export const GetWaterWorkOrder = data => {
  return service({
    url: '/api/WorkOrder/GetWaterWorkOrder',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data

    },
  })
}

export const GetSmokeWorkOrder = data => {
  return service({
    url: '/api/WorkOrder/GetSmokeWorkOrder',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data

    },
  })
}

export const GetGasWorkOrder = data => {
  return service({
    url: '/api/WorkOrder/GetGasWorkOrder',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data

    },
  })
}
//报警折线图
export const CountSOSWorkOrderOfDays = data => {
  return service({
    url: '/api/WorkOrder/CountSOSWorkOrderOfDays',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}

export const CountWaterWorkOrderOfDays = data => {
  return service({
    url: '/api/WorkOrder/CountWaterWorkOrderOfDays',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}

export const CountSmokeWorkOrderOfDays = data => {
  return service({
    url: '/api/WorkOrder/CountSmokeWorkOrderOfDays',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}

export const CountGasWorkOrderOfDays = data => {
  return service({
    url: '/api/WorkOrder/CountGasWorkOrderOfDays',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}

//服务管理tabkle

export const GetOrder = data => {
  return service({
    url: '/api/Order/GetOrder',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}
//获取业务档案关联设备信息
export const GetUserDevice = data => {
  return service({
    url: '/api/Equipment/GetUserDevice',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}

//获取用户工单分类统计

export const GetWorkOrderSumWithTypeList = data => {
  return service({
    url: '/api/Report/GetWorkOrderSumWithTypeList',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}
export const GetOrderSumWithTypeList = data => {
  return service({
    url: '/api/Report/GetOrderSumWithTypeList',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}


//获取用户近n个月的工单统计

export const GetWorkOrderSumMonthList = data => {
  return service({
    url: '/api/Report/GetWorkOrderSumMonthList',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}
//获取用户近n个月的订单统计

export const GetOrderSumMonthList = data => {
  return service({
    url: '/api/Report/GetOrderSumMonthList',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}

//智能社区安防异常预警列表
export const GetSecurityPager = data => {
  return service({
    url: '/api/WorkOrder/GetSecurityPager',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}
//获取安防设备列表 --地图打点
export const GeSecurityDevice = data => {
  return service({
    url: '/api/SecurityDevice/GetList',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}
//视频监控

export const GetVedioToken = id => {
  return service({
    url: '/api/SecurityDevice/GetVedioToken',
    method: 'get',
    params: {
      id: id,

    },
  })
}
//健康监护数据
export const GetHealthDailyDataV2 = data => {
  return service({
    url: '/api/Report/GetHealthDailyDataV2',
    method: 'get',
    params: {
      id: localStorage.getItem('id'),
      ...data
    },
  })
}
//租赁获取摄像头
export const GetEquipmentVedioToken = data => {
  return service({
    url: '/api/Equipment/GetVedioToken',
    method: 'get',
    params: {
      id: data
    },
  })
}
//设备报警
export const GetNurseBedAlarm = data => {
  return service({
    url: '/api/Equipment/GetNurseBedAlarm',
    method: 'get',
    params: {
      issueID: data
    },
  })
}
//获取ifram
export const GetNurseBedReportUrl = data => {
  return service({
    url: '/api/Equipment/GetNurseBedReportUrl',
    method: 'get',
    params: {
      issueID: data
    },
  })
}