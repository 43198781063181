<template>
  <div class="bigNav flex-a-center-j-between">
    <div class="titleIcon">
      <img src="@/assets/img/headLogo.png" alt="" class="headLogo" />
      <span class="headTitle"> {{ headerOptions.title }}</span>
    </div>
    <div class="headNav">
      <div
        v-for="(item, index) in headerOptions.navList"
        :key="index"
        class="navItem"
        @click="toPage(item.url)"
        :class="activeUrl == item.url ? 'navActive' : ''"
      >
        <img
          :src="activeUrl == item.url ? item.iconActive : item.icon"
          alt=""
          class="navIcon"
        />
        <span> {{ item.name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headerOptions: Object,
    activeUrl: String,
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {
    $router(val) {
      console.log(val);
    },
  },
  methods: {
    toPage(page) {
      this.$router.push(page);
      this.$emit("update:activeUrl", page);
    },
  },
};
</script>
<style lang="less" scoped>
.headLogo {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.headTitle {
  color: #2f69e6;
  font-size: 18px;
}
.bigNav {
  height: 50px;
  padding: 0 20px;

  background: #ffffff;
  box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.1);
}
.titleIcon {
  display: flex;
  align-items: center;
}
.headNav {
  display: flex;
  align-items: center;
}
.navItem {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
}
.navIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.navActive {
  background: linear-gradient(
    360deg,
    rgba(71, 107, 254, 0) 0%,
    rgba(71, 107, 254, 0.26) 100%
  );
  border-bottom: 4px solid #2f69e6;
  color: #2f69e6;
}
</style>
