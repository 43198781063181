import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/homeGuardianship"
  },
  //智能居家监护
  {
    path: '/homeGuardianship',
    name: 'homeGuardianship',
    component: Home,
    redirect: "/homeGuardianship/progress",
    children: [
      {
        path: "/homeGuardianship/progress",
        name: "progress",
        meta: { title: "实施进度" },
        component: () => import("@/views/homeGuardianship/progress.vue"),
      },
      {
        path: "/homeGuardianship/guardianship",
        name: "guardianship",
        meta: { title: "居家监护" },
        component: () => import("@/views/homeGuardianship/guardianship.vue"),
      },
      {
        path: "/homeGuardianship/serviceManagement",
        name: "serviceManagement",
        meta: { title: "服务管理" },
        component: () => import("@/views/homeGuardianship/serviceManagement.vue"),
      },
      {
        path: "/homeGuardianship/equipment",
        name: "equipment",
        meta: { title: "设备管理" },
        component: () => import("@/views/homeGuardianship/equipment.vue"),
      },
      {
        path: "/homeGuardianship/homeData",
        name: "homeData",
        meta: { title: "数据统计分析" },
        component: () => import("@/views/homeGuardianship/homeData.vue"),
      }


    ],
  },

  //智能社区安防
  {
    path: '/community',
    name: 'community',
    component: Home,
    redirect: "/community/device",
    children: [
      {
        path: "/community/device",
        name: "device",
        meta: { title: "设备管理" },
        component: () => import("@/views/community/device.vue"),
      },
      {
        path: "/community/security",
        name: "guardianship",
        meta: { title: "安防服务" },
        component: () => import("@/views/community/security.vue"),
      },
      {
        path: "/community/serviceManagement",
        name: "serviceManagement",
        meta: { title: "数据统计分析" },
        component: () => import("@/views/community/device.vue"),
      }


    ],
  },

  //智能服务管理
  {
    path: '/serviceManagement',
    name: 'serviceManagement',
    component: Home,
    redirect: "/serviceManagement/health",
    children: [
      {
        path: "/serviceManagement/health",
        name: "health",
        meta: { title: "健康设备" },
        component: () => import("@/views/serviceManagement/health.vue"),
      },
      {
        path: "/serviceManagement/services",
        name: "guardianship",
        meta: { title: "健康服务" },
        component: () => import("@/views/serviceManagement/services.vue"),
      },
      {
        path: "/serviceManagement/order",
        name: "serviceManagement",
        meta: { title: "服务订单" },
        component: () => import("@/views/serviceManagement/order.vue"),
      }
    ],
  },

  //辅具租赁
  {
    path: '/lease',
    name: 'lease',
    component: Home,
    redirect: "/lease/profile",
    children: [
      {
        path: "/lease/profile",
        name: "health",
        meta: { title: "用户档案" },
        component: () => import("@/views/lease/profile.vue"),
      },
      {
        path: "/lease/vitalsigns",
        name: "vitalsigns",
        meta: { title: "生命体征" },
        component: () => import("@/views/lease/vitalsigns.vue"),
      },
      {
        path: "/lease/healthreport",
        name: "healthreport",
        meta: { title: "健康报告" },
        component: () => import("@/views/lease/healthreport.vue"),
      },
      {
        path: "/lease/equipmentList",
        name: "guardianship",
        meta: { title: "健康服务" },
        component: () => import("@/views/lease/equipmentList.vue"),
      },

    ],
  }

]



const router = new VueRouter({
  routes
})

export default router
