/**
 * Created by zhangsanfeng on 2020/07/02.
 */

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  isBig: true,
  monaco: {}, // 编辑器value
  mattressInfo: {}, //床垫信息
  cameraInfo: {}//摄像头信息
}

const getters = {
  getMonaco(state) {
    return state.monaco
  },
  getIsBig(state) {
    return state.isBig
  },
  getMattressInfo(state) {
    return state.mattressInfo
  },
  getCameraInfo(state) {
    return state.cameraInfo
  }
}

const mutations = {
  setMonaco(state, data) {
    state.monaco = data
  },
  setIsBig(state, data) {
    state.isBig = data
  },
  setMattressInfo(state, data) {

    state.mattressInfo = data
  },
  setCameraInfo(state, data) {

    state.cameraInfo = data
  }

}

const actions = {

}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})
export default store
